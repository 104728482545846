import logo from './logo.svg';
import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import Cookies from 'universal-cookie';

function Login() {
  const cookies = new Cookies();
  const identity = cookies.get('identity');
  const payment_link = "https://pay.freekassa.ru/?m=27400&oa=150&o=1&s=ada18d746e0f6c85002add05e2fe8517&currency=RUB&us_identity=" + identity;

  const [payment, setPayment] = useState(<a href={payment_link}>Оплатить</a>);

  const firstUpdate = useRef(true);
  
  const navigate = useNavigate();

  const code = new URLSearchParams(useLocation().search).get('code');


  useEffect(() => {
    if (firstUpdate.current) {
        if (code) {
          fetch(window.api_site + 'yandex_token.php?code='+code)
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            if (json.id > 0) {
              cookies.set('identity', json.id);
              navigate("/login");
            }
          });
        }
        else {
          fetch(window.api_site + 'freekassa/payment.php?identity='+identity)
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            if (json.rows) setPayment(json.rows);
          });
        }

        firstUpdate.current = false;
        return;
    }
  }, []);
  return (
    <div>
      <Header/>
      {payment}
    </div>
  );
}

export default Login;
