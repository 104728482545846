import React from "react";
import '../App.css';

const LoadingTableRow = ({}) => {
   return (
            <tr>
               <td className="loading-red">Загрузка</td>
               <td>...</td>
               <td className="red">...</td>
               <td>...</td>
               <td className="lider">...</td>
            </tr>);
};
export default LoadingTableRow;