import logo from './logo.svg';
import React, { useRef, useEffect, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import TextResult from './components/TextResult';
import Table from './components/Table';
import Pagination from './components/Pagination';
import MetaTags from 'react-meta-tags';

function App() {
  const [requestLength, setRequestsLength] = useState('...');
  const [countPages, setCountPages] = useState(0);
  const [exportLink, setExportLink] = useState();

  return (
    <div>
      <Header/>
      <TextResult text={requestLength}/>
      <Table setRequestsLength={setRequestsLength} setCountPages={setCountPages} setExportLink={setExportLink}/>
      <Pagination countPages={countPages} exportLink={exportLink}/>
    </div>
  );
}

export default App;
