import React, { useRef, useEffect, useState } from 'react';
import '../App.css';
import { useNavigate, useLocation } from "react-router-dom";
import TableRow from "./TableRow";
import LoadingTableRow from './LoadingTableRow';

const Table = ({setRequestsLength, setCountPages, setExportLink}) => {
   const [tableRows, setTableRows] = useState(<LoadingTableRow/>);

   const search_get = useLocation().search;
   var search_text = new URLSearchParams(search_get).get('search') ?? '';
   var page = new URLSearchParams(search_get).get('page') ?? 0;

   const firstUpdate = useRef(true);

   useEffect(() => {
      if (firstUpdate.current) {
            // console.log(window.api_site + 'get_requests.php?key='+search_text.replace(' ', '%20').replace(',,', ',')+'&page='+page);
            fetch(window.api_site + 'get_requests.php?key='+search_text.replace(' ', '%20').replace(',,', ',')+'&page='+page)
            .then((response) => {
               return response.json();
            })
            .then((json) => {
               var tempTableRows;
               if (json.requests && json.length) {
                  // console.log(json);
                  setRequestsLength(json.length);
                  setCountPages(Math.ceil(json.length/16));
                  setExportLink(json.filename);
                  tempTableRows = json.requests.map((request) => {
                     return (<TableRow request={request} key={request.id}/>);
                  });
               }
               else {
                  // setRequestsLength(0);
                  // setCountPages(0);
                  // setExportLink(0);
                  // tempFilmCards = <NotFound/>;
               }
               setTableRows(tempTableRows);
            });

            firstUpdate.current = false;
            return;
         }
   }, []);

   return (<div className="result">
      <div className="container">		
         
            <table>
               <tr className="result_title">
                  <td className="result_title-head">Поисковая фраза</td>
                  <td className="result_title-head">Кол-во</td>
                  <td className="result_title-head">Динамика, %</td>
                  <td className="result_title-head">Товаров</td>
                  <td className="result_title-head">Выгода, % </td>
               </tr> 		
               {tableRows}
            </table>	
         
      </div>
   </div>);
};
export default Table;