import React from "react";
import '../App.css';
import PaginationItem from "./PaginationItem";
import {useLocation} from "react-router-dom";
import { Link } from 'react-router-dom';

const Pagination = ({countPages, exportLink}) => {
   const search = useLocation().search;
   var page = new URLSearchParams(search).get('page') ?? 1;
   var search_text = new URLSearchParams(search).get('search') ?? '';
   // if (search_text == '') countPages = 0;
   var prev = parseInt(page) - 1;
   if (prev < 1) prev = 1;
   var next = parseInt(page) + 1;
   if (next >= countPages) next = countPages;

   var links = [];
   links.push(<PaginationItem link={'?search='+search_text+'&page='+prev} text={'предыдущая'} classLink={'prev'}/>);
   if (page > 1 && page != 2) {
      links.push(<PaginationItem link={'?search='+search_text+'&page=1'} text={'1'} classLink={''}/>);
      links.push(<PaginationItem link={'#'} text={'...'} classLink={''}/>);
   }
   if (page > 1) {
      links.push(<PaginationItem link={'?search='+search_text+'&page='+prev} text={prev} classLink={''}/>);
   }
   var maxPage = parseInt(page) + (page == 1 ? 4 : 3);
   if (maxPage > countPages) maxPage = countPages;
   for (var i = page; i < maxPage; i++) {
      if (i == page)
         links.push(<PaginationItem link={'?search='+search_text+'&page='+i} text={i} classLink={'active'}/>);
      else
         links.push(<PaginationItem link={'?search='+search_text+'&page='+i} text={i} classLink={''}/>);
   }
   if (page != countPages) {
      if ((page + 1) != countPages)
         links.push(<PaginationItem link={'#'} text={'...'} classLink={''}/>);
      links.push(<PaginationItem link={'?search='+search_text+'&page='+countPages} text={countPages} classLink={''}/>);
      links.push(<PaginationItem link={'?search='+search_text+'&page='+next} text={'следующая'} classLink={'next'}/>);
   }
   // if (countPages == 0) return ('');
   return (<div className="container export">
   <div className="export_action">		
      <div className="pages">
         {links}
      </div>
      <div><button type="button" className="btn btn-success"><Link to={exportLink} target="_blank">Экспорт в CSV</Link></button></div>		
   </div>	
</div>);
};
export default Pagination;