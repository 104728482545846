import logo from './logo.svg';
import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import Cookies from 'universal-cookie';

function NoMatch() {
  return (
    <div>
      <Header/>
      <p>Запрашиваемая страница не найдена!</p>
      <p class="404">404</p>
    </div>
  );
}

export default NoMatch;
