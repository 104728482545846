import React from "react";
import '../App.css';

const TableRow = ({request}) => {
   var products_class = 'n';
   var dynamics_class = 'n';
   var ratio_class = 'n';
   if (request.products == 0) products_class = 'noload';
   if (request.dynamics == 0) dynamics_class = 'noload';
   if (request.ratio == 0) ratio_class = 'noload';
   return (
            <tr>
               <td>{request.key}</td>
               <td>{request.requests}</td>
               <td className={"red "+dynamics_class}>{Math.round(request.dynamics)} %</td>
               <td className={"lider "+products_class}>{request.products}</td>
               <td className={"lider "+ratio_class}>{Math.round(request.ratio)} %</td>
            </tr>);
};
export default TableRow;